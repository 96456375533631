<template>
  <div>
    <div class="menu">
      <span>Mypage</span>
      <img src="@/assets/images/icn-arrow_right.svg" alt="arrow" />
      <span class="active">Search History</span>
    </div>
    <article class="inner">
      <ul>
        <li v-for="(item, index) in searchList" :key="index">
          <div class="flexB">
            <span>{{ moment(item.createdAt).format("YYYY.MM.DD hh:mm") }}</span>
            <h3>
              <em>{{ moment(item.createdAt).format("YYYY.MM.DD hh:mm") }}</em>
              <span>{{ item.word }}</span>
            </h3>
            <button @click="handleDelete(item._id)"></button>
          </div>
        </li>

        <!-- <li>
          <div class="flexB">
            <span>2022.05.10</span>
            <h3><span>Lorem Ipsum</span><em>/</em><span>California</span></h3>
          </div>
        </li>
        <li>
          <div class="flexB">
            <span>2022.05.10</span>
            <h3><span>Lorem Ipsum</span><em>/</em><span>California</span></h3>
          </div>
        </li>
        <li>
          <div class="flexB">
            <span>2022.05.10</span>
            <h3><span>Lorem Ipsum</span><em>/</em><span>California</span></h3>
          </div>
        </li>
        <li>
          <div class="flexB">
            <span>2022.05.10</span>
            <h3><span>Lorem Ipsum</span><em>/</em><span>California</span></h3>
          </div>
        </li>
        <li>
          <div class="flexB">
            <span>2022.05.10</span>
            <h3><span>Lorem Ipsum</span><em>/</em><span>California</span></h3>
          </div>
        </li>
        <li>
          <div class="flexB">
            <span>2022.05.10</span>
            <h3><span>Lorem Ipsum</span><em>/</em><span>California</span></h3>
          </div>
        </li>
        <li>
          <div class="flexB">
            <span>2022.05.10</span>
            <h3><span>Lorem Ipsum</span><em>/</em><span>California</span></h3>
          </div>
        </li>
        <li>
          <div class="flexB">
            <span>2022.05.10</span>
            <h3><span>Lorem Ipsum</span><em>/</em><span>California</span></h3>
          </div>
        </li>
        <li>
          <div class="flexB">
            <span>2022.05.10</span>
            <h3><span>Lorem Ipsum</span><em>/</em><span>California</span></h3>
          </div>
        </li> -->
      </ul>
      <!-- <el-collapse accordion>
        <el-collapse-item>
          <template slot="title">
            <span>2022.05.10</span>
            <h3><span>Lorem</span> <span>/</span> <span>California</span></h3>
          </template>
          <div class="flexB">
            <div>
              <h4>검색내역</h4>

              <p>
                <span>설문 시작일</span>
                2022.05.10
              </p>

              <p><span>설문 종료일</span>2022.05.10</p>
              <p>
                <span>설문 상태</span>
                <span>마감</span>
              </p>
            </div>
            <div>
              <h4>참여 정보</h4>
              <p>
                <span>참여일</span>
                2022년 05월 10일 13:33
              </p>
              <p>
                <span>참여 금액</span>
                500,00원
              </p>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse> -->
      <NoData v-if="searchList.length < 1" />
      <div class="pagination" v-if="searchList.length > 0">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          :pager-count="4"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </article>
  </div>
</template>
<script>
import moment from "moment";
// import { mapState } from "vuex";
//import { format } from "@/mixins/format";
import { fetchWordList, removeWord } from "@/api/medical";
import NoData from "@/components/contents/Nodata";
export default {
  // mixins: [format],
  components: { NoData },
  data() {
    return {
      moment: moment,
      searchList: [],
      total: 0,
      page: 1,
    };
  },

  computed: {
    // ...mapState(["userId"]),
  },
  mounted() {
    this.getSearchList();
  },
  methods: {
    // handleMove(id) {
    //   this.$router.push({ name: "surveyDetail", params: { id: id } });
    // },
    handleDelete(wordId) {
      console.log("handleDelete", wordId);
      let data = {
        wordId: wordId,
      };
      removeWord(data).then((res) => {
        if (res.data.status == 200) {
          console.log(res.data.status);
          this.getSearchList();
        } else {
          return alert(res.data.message);
        }
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getSearchList();
    },
    getSearchList() {
      let params = {
        page: this.page,
      };
      fetchWordList(params).then((res) => {
        if (res.data.status == 200) {
          this.searchList = res.data.data;
          this.total = res.data.total;
          this.page = res.data.page;
        } else {
          return alert(res.data.message);
        }
      });
    },
  },
};
</script>
