<template>
  <div class="myInfo">
    <div class="menu">
      <span>Mypage</span>
      <img src="@/assets/images/icn-arrow_right.svg" alt="arrow" />
      <span class="active">Change Password</span>
    </div>
    <article class="inner">
      <div class="form">
        <div class="flex">
          <div class="password">
            <div class="flex">
              <p>Current Password <span>*</span></p>
              <input type="password" v-model="password" />
            </div>
            <div class="flex">
              <p>New Password <span>*</span></p>
              <input type="password" v-model="newPassword" />
            </div>
            <div class="flex">
              <p>Repeat Password <span>*</span></p>
              <input type="password" v-model="passwordConfirm" />
            </div>
          </div>
          <button class="point" @click="updatePassword()">
            Change Password
          </button>
        </div>
      </div>
    </article>
  </div>
</template>
<script>
import { updateUserPwd } from "@/api/index";
import { format } from "@/mixins/format";
// import { mapState } from "vuex";

export default {
  mixins: [format],
  data() {
    return {
      password: "",
      passwordConfirm: "",
      newPassword: "",
    };
  },
  computed: {
    // ...mapState(["email"]),
  },
  methods: {
    updatePassword() {
      if (this.password == "") {
        return alert("Enter current password.");
      } else if (this.newPassword == "") {
        return alert("Enter new password.");
      } else if (this.password == this.newPassword) {
        return alert("Confirm new password.");
      } else if (this.checkPassword(this.newPassword) == false) {
        return;
      } else if (this.passwordConfirm != this.newPassword) {
        return alert("Please make sure your new passwords match.");
      }

      let data = {
        // email: this.email,
        password: this.password,
        newPassword: this.newPassword,
      };
      updateUserPwd(data).then((res) => {
        if (res.data.status == 200) {
          //this.visible = true;
          this.password = "";
          this.newPassword = "";
          this.passwordConfirm = "";
          // this.visible = false;
          alert("Password has been updated successfully.");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
