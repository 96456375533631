<template>
  <div class="container mypage">
    <div class="top"></div>
    <div class="base flexB">
      <section class="contents myMenu">
        <article class="inner">
          <div class="profile">
            <h3>{{ firstName }} {{ lastName }}</h3>
            <!--  {{ lastName }} <h3>{{`${firstName} ${lastName}`}}</h3> -->
            <p>{{ email }}</p>
            <!-- <h3>{{ username }}</h3>
            <p>{{ email }}</p> -->
            <router-link to @click.native="logout">Log out</router-link>
          </div>
          <ul>
            <li :class="{ active: path == 'myHistory' }">
              <router-link to @click.native="handleTab('myHistory')"
                >Search History</router-link
              >
            </li>
            <!-- <li>
              <router-link to="/mypage/myQna">나의 문의내역</router-link>
            </li>-->
            <li :class="{ active: path == 'edit' }">
              <router-link to @click.native="handleTab('edit')"
                >Update Profile</router-link
              >
            </li>
            <li :class="{ active: path == 'pw' }">
              <router-link to @click.native="handleTab('pw')"
                >Change Password</router-link
              >
            </li>
            <li>
              <router-link to @click.native="withdraw">Deactivate</router-link>
            </li>
            <!-- <li>
              <router-link to @click.native="logout">Log out</router-link>
            </li> -->
          </ul>
        </article>
      </section>
      <section class="contents myWrap subpage">
        <article class="box">
          <MyHistory v-if="path == 'myHistory'" />
          <UserInfo v-else-if="path == 'edit'" />
          <UserPwd v-else-if="path == 'pw'" />
        </article>
      </section>
    </div>
  </div>
</template>
<script>
import MyHistory from "@/components/contents/MyHistory";
import UserInfo from "@/views/mypage/UserInfo";
import UserPwd from "@/views/mypage/UserPwd";
import { createWithdraw } from "@/api/index";

import { mapState } from "vuex";

export default {
  components: { MyHistory, UserInfo, UserPwd },
  data() {
    return {
      callActive: false,
      path: "myHistory",
    };
  },
  computed: {
    ...mapState(["firstName", "lastName", "email", "type"]),
  },
  methods: {
    handleTab(name) {
      this.path = name;
    },
    withdraw() {
      const result = confirm(
        "Would you like to cancel your membership? All personal information will be deleted upon cancellation."
      );
      if (result == false) {
        return;
      }
      createWithdraw().then((res) => {
        if (res.data.status == 200) {
          // this.logout;
          this.$store.dispatch("LOGOUT");
        } else {
          alert(res.data.message);
        }
      });
    },
    logout() {
      let result = confirm("Are you sure you want to log out?");
      if (result == false) {
        return;
      }
      if (this.type == "GOOGLE") {
        window.google.accounts.id.disableAutoSelect();
      }
      this.$store.dispatch("LOGOUT");
    },
  },
};
</script>
